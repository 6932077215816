// rootReducer.js
import { combineReducers } from 'redux';
import LayoutReducer from './layouts/reducer';
import OfferReducer from './offer/reducer';
import UomReducer from './uom/reducer';
import AddOfferReducer from './offer/addoffer/reducer';
import CreateCustomerReducer from './customer/create/reducer';
import UpdateCustomerReducer from './customer/update/reducer';
import FetchCustomerReducer from './customer/fetchcustomer/reducer';
import AddUomReducer from './uom/adduom/reducer';
import CustomerListReducer from './customer/customerlist/reducer';
import SimulateReducer from './customer/simulate/reducer';
import RateUsageReducer from './customer/rateusage/reducer';
import CancelSubscriptionReducer from './customer/cancelsubscription/reducer';
import ServiceReducer from '../slices/services/reducer';
import ResourceReducer from '../slices/resources/reducer';
import StationReducer from '../slices/stations/reducer';
import ReservationReducer from '../slices/reservations/reducer';
import CreateServiceReducer from '../slices/services/addservice/reducer';
import CreateResourceReducer from '../slices/resources/addresources/reducer';
import ConfigReducer from '../slices/services/addservice/eventconfiguration/reducer';
import permissionsReducer from '../permissionsSlice';
import ProvisionalReducer from "../slices/services/addservice/provisionalattribute/reducer"
import EditStationReducer from "../slices/stations/editstation/reducer"
import PostReservationReducer from "../slices/reservations/postreservation/reducer"
import UpdateReservationReducer from "../slices/reservations/updatereservation/reducer"
import GetReservationReducer from "../slices/reservations/getreservation/reducer"
import DeleteResourceReducer from "../slices/resources/deleteresource/reducer"
import CitiesReducer from "../slices/customer/cities/reducer"
import StatesReducer from "../slices/customer/states/reducer"
import CountriesReducer from "../slices/customer/countries/reducer"
import DefaultLoginReducer from "../slices/login/reducer"
import welcomeToastReducer from "../slices/welcomeToaster/reducer"
import successToastReducer from "../slices/toastMessage/reducer"
import DocumentToastReducer from "../slices/toastMessage/reducer"
import CustomerAllDataReducer from "../slices/reservations/customerlist/reducer"
import DocumentUploadReducer from "../slices/customer/documentupload/reducer"
import GetBillsForCustomerReducer from "../slices/customer/getbills/reducer"
import DocumentListReducer from "../slices/customer/documentlist/reducer"
import VerifyDocumentReducer from "../slices/customer/documentverify/reducer"
import UpdateDocumentReducer from "../slices/customer/documentupdate/reducer"
import DeleteDocumentReducer from "../slices/customer/documentdelete/reducer"
import ViewDocumentReducer from "../slices/customer/documentview/reducer"
import DownloadDocumentReducer from "../slices/customer/documentdownload/reducer"
import AllOrderReducer from "../slices/orderManagement/reducer"
import CreateOrderReducer from "../slices/orderManagement/CreateOrder/reducer"
import SelectOrderReducer from "../slices/orderManagement/selectedOrder/reducer"
import PartnerReducer from "../slices/PartnerMangement/reducer"
import CreatePartnerReducer from "../slices/PartnerMangement/CreatePartner/reducer"
import PolicyListReducer from "../slices/PartnerMangement/policylist/reducer"
import SelectDeviceReducer from "../slices/orderManagement/CreateOrder/SelectDevice/reducer"
import InvoiceStatisticsReducer from "../slices/invoices/invoicestatistics/reducer"
import InvoiceListReducer from "../slices/invoices/invoicelist/reducer"
import InvoiceListForCustomerReducer from "../slices/invoices/invoiceforcustomer/reducer"
import SelectedOfferForPartnerReducer from "../slices/PartnerMangement/OfferList/reducer"
import SelectedPartnerReducer from '../slices/PartnerMangement/PartnerList/reducer'
import DeviceSliceReducer from "../slices/Device/Devicelist/reducer"
import addDeviceSliceReducer from "../slices/Device/Adddevice/reducer"
import editDeviceSliceReducer from "../slices/Device/EditDevice/reducer"
import fetchDeviceSliceReducer from "../slices/Device/FetchDevice/reducer"
import cancelStatusSliceReducer from "../slices/Device/DeleteStatus/reducer"
import DocumentUpdateFileReducer from "../slices/customer/documentupdatefile/reducer"
import PartnerListReducer from "../slices/PartnerMangement/ListingPartner/reducer"
import EditPartnerReducer from "../slices/PartnerMangement/EditPartner/reducer"
import PartnerViewListReducer from "../slices/PartnerMangement/ViewListPartner/reducer"
import PartnerDocumentUploadReducer from "../slices/PartnerMangement/PartnerDocumentUpload/reducer"
import GetOrderByIdReducer from "../slices/orderManagement/getorder/reducer"
import SettlementPolicyReducer from "../slices/settlementPolicy/AddPolicy/reducer"
import GetSettlementPolicyReducer from "../slices/settlementPolicy/Getpolicy/reducer"
import deletePolicySliceReducer from "../slices/settlementPolicy/DeletePolicy/reducer"
import CustomerEventReducer from "../slices/customer/events/reducer"
import GetBillsForPartnerReducer from "../slices/PartnerMangement/GetPartnerBill/reducer"
import GetOfferReducer from "../slices/offer/getoffer/reducer"
import UpdateOfferReducer from "../slices/offer/updateoffer/reducer"
import GetPaymentForCustomerReducer from '../slices/payment/reducer'
import GetPopularPlanReducer from '../slices/PopularPlan/reducer'
import GetOrderByCustomerIdReducer from '../slices/orderManagement/GetOrderByCustomerId/reducer'
import GetStatisticDataForCustomerReducer from '../slices/customerDashboard/reducer'
import GetAllPoliciesReducer from '../slices/Policy/reducer'
import DeletePoliciesReducer from '../slices/Policy/DeletePolicy/reducer'
import CreatePoliciesReducer from '../slices/Policy/CreatePolicy/reducer'
import GetAllPoliciesByNameReducer from '../slices/Policy/GetPolicyByName/reducer'
import UpdatePoliciesReducer from '../slices/Policy/UpadatePolicy/reducer'
import CancelOrderReducer from '../slices/orderManagement/cancelorder/reducer'
import SearchedCustomerReducer from '../slices/customer/customerserach/reducer'
import GetMvnoDashboardReducer from '../slices/MvnoDashboard/reducer'
import GetAttributeByTypeReducer from "../slices/attribute/getattributebytype/reducer"
import GetAttributeByNameReducer from "../slices/attribute/getattributebyname/reducer"
import GetAllUserReducer from '../slices/UserManagement/reducer'
import CreateUserReducer from "../slices/UserManagement/CreateUser/reducer"
import GetAllUserByNameReducer from "../slices/UserManagement/GetUserByName/reducer"
import UpdateUserReducer from "../slices/UserManagement/UpdateUser/reducer"
import DeleteUserReducer from '../slices/UserManagement/DeleteUser/reducer'
import GetOperatorListReducer from '../slices/OperatorManagement/reducer'
import EditOperatorReducer from '../slices/OperatorManagement/EditOperator/reducer'
import CreateOperatorReducer from '../slices/OperatorManagement/CreateOperator/reducer'
import GetOperatorbyId from '../slices/OperatorManagement/GetOperatorbyID/reducer'
import SubscribeOrderReducer from '../slices/orderManagement/Subscribe/reducer'
import GetCustomerAI from '../slices/AI/reducer'
import ChangePlanReducer from './customer/changeplan/reducer'
import BalanceReducer from './customer/balance/reducer'
import GetAllTasksReducer from "./tasks/getalltasks/reducer"
import GetTaskByIdReducer from "./tasks/gettaskbyid/reducer"
import GetStatisticsOfTasksReducer from "./tasks/getstatistics/reducer"
import LogoViewReducer from '../slices/Logo/reducer'
import ResourceByIdReducer from '../slices/resources/getresourcebyID/reducer'
import CreateNewPasswordReducer from '../slices/UserManagement/PasswordManagement/reducer'
import SystemLogoViewReducer from "../slices/Logo/SystemUserViewLogo/reducer"
import DeleteCustomerByIdReducer from '../slices/customer/deletecustomer/reducer'
import EditResourceReducer from '../slices/resources/updateresource/reducer'
import DeleteUomReducer from '../slices/uom/deleteUom/reducer'
import GetUomByIdReducer from '../slices/uom/getUomByName/reducer'
import UpdateUomReducer from '../slices/uom/updateUom/reducer'
import ResetPasswordReducer from '../slices/ResetPassword/reducer'
import ExpiryCheckReducer from '../slices/ResetPassword/expiryCheck/reducer'
import ResetPasswordCreateReducer from '../slices/ResetPassword/ResetPasswordCreate/reducer'
import ViewReducer from '../slices/View/reducer'
import CreateViewReducer from '../slices/View/CreateView/reducer'
import SetPasswordCreateReducer from '../slices/SetPassword/reducer'
import EmailVerificationReducer from "../slices/EmailVerification/reducer"
const rootReducer = combineReducers({
    DefaultLogin: DefaultLoginReducer,
    Layout: LayoutReducer,
    Offer: OfferReducer,
    AddOffer: AddOfferReducer,
    Uom: UomReducer,
    AddUom: AddUomReducer,
    GetUomById:GetUomByIdReducer,
    DeleteUom : DeleteUomReducer,
    UpdateUom: UpdateUomReducer,
    CreateCustomer: CreateCustomerReducer,
    UpdateCustomer: UpdateCustomerReducer,
    FetchCustomer: FetchCustomerReducer,
    CustomerList: CustomerListReducer,
    Simulate: SimulateReducer,
    RateUsage: RateUsageReducer,
    CancelSubscription: CancelSubscriptionReducer,
    Services: ServiceReducer,
    Resources: ResourceReducer,
    Stations: StationReducer,
    Reservations: ReservationReducer,
    CreateService: CreateServiceReducer,
    CreateResource: CreateResourceReducer,
    Config: ConfigReducer,
    Provisional: ProvisionalReducer,
    permissions: permissionsReducer,
    EditStation: EditStationReducer,
    PostReservation: PostReservationReducer,
    UpdateReservation: UpdateReservationReducer,
    GetReservation: GetReservationReducer,
    DeleteResource: DeleteResourceReducer,
    Countries: CountriesReducer,
    States: StatesReducer,
    Cities: CitiesReducer,
    welcomeToast: welcomeToastReducer,
    SuccessToast: successToastReducer,
    CustomerAllData: CustomerAllDataReducer,
    Partner: PartnerReducer,
    CreatePartner: CreatePartnerReducer,
    EditPartner: EditPartnerReducer,
    DocumentUpload: DocumentUploadReducer,
    GetBillsForCustomer: GetBillsForCustomerReducer,
    DocumentList: DocumentListReducer,
    VerifyDocument: VerifyDocumentReducer,
    UpdateDocument: UpdateDocumentReducer,
    DeleteDocument: DeleteDocumentReducer,
    ViewDocument: ViewDocumentReducer,
    DownloadDocument: DownloadDocumentReducer,
    DocumentToast: DocumentToastReducer,
    OrderList: AllOrderReducer,
    CreateOrder: CreateOrderReducer,
    PolicyList: PolicyListReducer,
    SelectOrder: SelectOrderReducer,
    SelectedDevice: SelectDeviceReducer,
    AddDevice: addDeviceSliceReducer,
    updateDevice: editDeviceSliceReducer,
    fetchDevice: fetchDeviceSliceReducer,
    cancelStatus: cancelStatusSliceReducer,
    device: DeviceSliceReducer,
    InvoiceStatistics: InvoiceStatisticsReducer,
    InvoiceList: InvoiceListReducer,
    InvoiceListForCustomer: InvoiceListForCustomerReducer,
    SelectedOffer:SelectedOfferForPartnerReducer,
    SelectedPartner:SelectedPartnerReducer,
    PartnerList: PartnerListReducer,
    PartnerViewList: PartnerViewListReducer,
    DocumentUpdateFile: DocumentUpdateFileReducer,
    PartnerDocumentUpload: PartnerDocumentUploadReducer,
    GetOrderById: GetOrderByIdReducer,
    SettlementPolicy: SettlementPolicyReducer,
    GetSettlementPolicy:GetSettlementPolicyReducer,
    deletePolicy :deletePolicySliceReducer,
    CustomerEvent : CustomerEventReducer,
    GetBillsForPartner:GetBillsForPartnerReducer,
    GetOffer: GetOfferReducer,
    UpdateOffer: UpdateOfferReducer,
    GetPaymentForCustomer:GetPaymentForCustomerReducer,
    GetPopularPlan:GetPopularPlanReducer,
    GetOrderByCustomerId:GetOrderByCustomerIdReducer,
    GetStatisticDataForCustomer:GetStatisticDataForCustomerReducer,
    GetAllPolicies:GetAllPoliciesReducer,
    DeletePolicies:DeletePoliciesReducer,
    CreatePolicies:CreatePoliciesReducer,
    GetAllPoliciesByName:GetAllPoliciesByNameReducer,
    UpdatePolicies:UpdatePoliciesReducer,
    CancelOrder: CancelOrderReducer,
    SearchedCustomer: SearchedCustomerReducer,
    GetMvnoDashboard:GetMvnoDashboardReducer,
    GetAttributeByType: GetAttributeByTypeReducer,
    GetAttributeByName : GetAttributeByNameReducer,
    GetAllUser:GetAllUserReducer,
    CreateUser:CreateUserReducer,
    GetAllUserByName:GetAllUserByNameReducer,
    UpdateUser:UpdateUserReducer,
    DeleteUser:DeleteUserReducer,
    GetMvnoDashboard:GetMvnoDashboardReducer,
    GetOperatorList:GetOperatorListReducer,
    EditOperator:EditOperatorReducer,
    CreateOperator:CreateOperatorReducer,
    GetOperator:GetOperatorbyId,
    SubscribeOrder:SubscribeOrderReducer,
    GetCustomerAIbyId:GetCustomerAI,
    CreateNewPassword:CreateNewPasswordReducer,
    ChangePlan: ChangePlanReducer,
    Balance:BalanceReducer,
    GetAllTasks:GetAllTasksReducer,
    GetTaskById:GetTaskByIdReducer,
    GetStatisticsOfTasks:GetStatisticsOfTasksReducer,
    LogoView:LogoViewReducer,
    ResourceById:ResourceByIdReducer,
    DeleteCustomerById: DeleteCustomerByIdReducer,
    EditResource:EditResourceReducer,
    ResetPassword:ResetPasswordReducer,
    ExpiryCheck:ExpiryCheckReducer,
    SystemLogoView:SystemLogoViewReducer,
    ResetPasswordCreate:ResetPasswordCreateReducer,
    View:ViewReducer,
    CreateView:CreateViewReducer,
    SetPasswordCreate:SetPasswordCreateReducer,
    EmailVerification:EmailVerificationReducer
});

export default rootReducer;