import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Form,
} from "reactstrap";
import { Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import Attribute from "./Attribute";
import { useDispatch, useSelector } from "react-redux";
import { getAllService } from "../../../slices/services/thunk";
import { addService } from "../../../slices/services/addservice/thunk";
import { getConfig } from "../../../slices/services/addservice/eventconfiguration/thunk";
import { getAllAttribute } from "../../../slices/services/addservice/provisionalattribute/thunk";
import { ToastContainer } from "react-toastify";
import {
  EventOptions,
  getProvisionOptions,
  ServiceOptions,
  initialFormValues,
  validationSchema,
  AttributeTypeOptions,

} from "./constants/initialvalues";
import { createJsonObj } from "./constants/servicejson";
import { Loader } from "../../Utils/Loader";
import CommonButton from "../../Utils/CommonButton";
import { PAGE_TITLES } from "../../Common/constants";
import formSchema from "../../Utils/schema.json";
import { RenderField } from "../../Utils/renderFields";
import { RenderErrorMessage } from "../../Utils/renderErrorMessages";
import { LabelText } from "../../Utils/Input";
import { RenderTable } from "../../Utils/renderTable";
import { customerIdData } from "../../Customers/constants/constantFunctionsView";
import { getServiceByCode } from "../../../slices/services/getservice/thunk";

export default function AddService(initialAIServiceValues) {
  const [state, setState] = useState(false);
  const [startDate, setStartDate] = useState();
  const [selectedAttributeName, setSelectedAttributeName] = useState(null);
  const [loading, setLoading] = useState("");
  const [enableReinitialize,setEnableReinitialize]=useState(false)
  const isCreate = location.pathname.includes('create-service')
  const isClone = location.pathname.includes('clone-service')
  let navigate = useNavigate();
  const serviceCode = customerIdData()
  const isAiPath=location.pathname.includes('chatbot')

  // let initialValues = initialFormValues
  let initialValues = {
    name: "",
    code: "",
    eventConfigurations: '',
    startsOn: null,
    endson: null,
    services: "",
    attributeList: []
};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllService());
    if(isAiPath){
    dispatch(getConfig());
    }
    dispatch(getAllAttribute(setLoading));
  }, [dispatch]);

  const options = ServiceOptions();
  const eventOptions = EventOptions();
  const provisionalAttribute = useSelector(
    (state) => state.Provisional.provisional
  );
  const provisionOptions = getProvisionOptions(provisionalAttribute).provisionalItems;
  const usageoptions = getProvisionOptions(provisionalAttribute).usageItems;
  const onSubmit = (values) => {
    createJsonObj(values, state, AddService);
  };
  const AddService = (jsonObj) => {
    dispatch(addService(jsonObj, setLoading, navigate));
  };
  const serviceOptions = [
    {
      name: "eventConfigurations",
      value: "Service",
      options: eventOptions
    },
    {
      name: "services",
      value: "Service",
      options: options
    }
  ]
  useEffect(() => {
    if (isClone) {
      dispatch(getServiceByCode(setLoading, serviceCode, initialValues,serviceOptions,setState))
    }
  }, [dispatch])

  useEffect(()=>{
    if(isCreate){
      setEnableReinitialize(true)
    }
  },[isCreate])

  return (
    <React.Fragment>
      <div className={!isAiPath ? "page-content" : "pb-5"}>
        <Formik
          validationSchema={(values) => validationSchema(values, state)}
          initialValues={isAiPath?initialAIServiceValues.initialAIServiceValues:initialValues}
          onSubmit={async (values) => {
            if (
              values.name !== "" &&
              values.code !== "" &&
              values?.startsOn !== "" &&
              values.endson !== ""
            ) {
              onSubmit(values);
            }
          }}
          enableReinitialize={enableReinitialize}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
            setTouched,
            isValid,
            dirty,
            resetForm,
          }) => (
            <Form
              id="csms"
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <Container fluid>
                {isAiPath?null:
                <BreadCrumb title={PAGE_TITLES.ADD_SERVICE} pageTitle={PAGE_TITLES.SERVICE_LIST} />
}
                <ToastContainer position="top-center" />
                <div className="listingjs-table " id="customerList">
                  <div className="live-preview">
                    {formSchema.map((section, sectionIndex) => (
                      <>
                        {section.title === "Service" && <div key={sectionIndex}>
                          {section.sections && section.sections.map((subSection, subSectionIndex) => (
                            <Card key={subSectionIndex} className='mb-4'>
                              {subSection?.sectionTitle && <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">{subSection?.sectionTitle}</h4>
                              </CardHeader>}
                              <CardBody>
                                <Row className='gy-4 py-2'>
                                  {subSection.fields.filter(field => {
                                    if (field.name === "services" && !state) {
                                      return false
                                    }
                                    return true
                                  }).map((field, fieldIndex) => (
                                    <Col
                                      xxl={field.name === "description" ? 12 : 4}
                                      md={field.name === "description" ? 12 : 6}
                                      lg={field.name !== "description" ? 12 : 6}
                                      key={`${subSectionIndex}-${fieldIndex}`}
                                    >
                                      <LabelText
                                        htmlFor={field.name}
                                        className={field.required ? "text-danger" : ""}
                                        displayText={field.label}
                                        important={field.required ? "*" : ""}
                                      />
                                      <RenderField field={field}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        touched={touched}
                                        errors={errors} setStartDate={setStartDate} startDate={startDate}
                                        setFieldTouched={setFieldTouched} setIsChecked={setState} isChecked={state}
                                        setFieldValue={setFieldValue} options={serviceOptions} />
                                      <RenderErrorMessage
                                        field={field}
                                        touched={touched}
                                        errors={errors} />
                                    </Col>
                                  ))}
                                </Row>
                              </CardBody>
                            </Card>
                          ))}
                        </div>}
                      </>
                    ))}
                    <Col xl={12}>
                      <Attribute
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        setTouched={setTouched}
                        handleChange={handleChange}
                        provisonalAttribute={provisionalAttribute}
                        provisionOptions={provisionOptions}
                        usageoptions={usageoptions}
                        selectedAttributeName={
                          selectedAttributeName
                        }
                        setSelectedAttributeName={
                          setSelectedAttributeName
                        }
                      />
                    </Col>
                    <Row lg={6} className=" justify-content-end mb-4">
                      <div className="live-preview">
                        <div className="d-flex justify-content-end gap-2 ">
                          <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/service')} buttonText={"Cancel"} />
                          <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} buttonAction={handleSubmit} />
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Container>
            </Form>
          )}
        </Formik>
        {loading && <Loader />}
      </div>
    </React.Fragment>
  );
}
