export const LOGIN_API = "/login"
export const UOM_LISTING_API = "/getAllUnitOfMeasurements"
export const ADD_UOM_API = "/unitOfMeasurement"
export const GET_UOM_BY__ID_API = "/getUnitOfMeasurement"
export const RESOURCE_LISTING_API = "/getAllResources"
export const GET_RESOURCE_BY__ID_API = "/getResource"
export const ADD_RESOURCE_API = "/resource"
export const DELETE_RESOURCE_API = "/deleteResource"
export const DELETE_CUSTOMER_API = "/deleteCustomer"
export const SERVICE_LISTING_API = "/getAllServices"
export const ADD_SERVICE_API = "/service"
export const GET_ALL_ATTRIBUTE_API = "/attributes"
export const CREATE_EDIT_GET_ATTRIBUTE_API = "/attribute"
export const CONFIG_API = "/config/query"
export const OFFER_LISTING_API = "/getAllOffers"
export const DELETE_OFFER_API = "/deleteOffer"
export const ADD_OFFER_API = "/offer"
export const GET_OFFER_API = "/getOffer"
export const UPDATE_OFFER_API = "/updateOffer"
export const STATION_LISTING_API ="/stations"
export const RESERVATION_LISTING_API = "/reservation/query"
export const UPDATE_RESERVATION_API = "/updateReservation"
export const POST_RESERVATION_API = "/reserve"
export const GET_RESERVATION_API = "/getReservation"
export const UNSUBSCRIBE_OFFER_API = "/unsubscribe"
export const REGISTER_CUSTOMER_API = "/registerCustomer"
export const CUSTOMER_LISTING_API = "/customer/query"
export const CUSTOMER_EVENTS_API = "/events"
export const GET_CUSTOMER_API = "/customer"
export const SEARCH_CUSTOMER_API = "/customers"
export const RATE_USAGE_API = "/rateUsage"
export const BALANCE_API = "/balance"
export const UPDATE_CUSTOMER_API = "/updateCustomer"
export const COUNTRIES_API = "/iso"
export const STATES_API = "/states"
export const CITIES_API = "/state/cities"
export const DASHBOARD_METRICS_API = "/stations/metrics/customer"
export const DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API = "/document"
export const LIST_DOCUMENTS_API = "/documents"
export const VERIFY_DOCUMENT_API = "/document/verify"
export const GET_BILLS_API = "/bills"
export const ORDERS_LIST_API = "/tmf-api/productOrderingManagement/v5/productOrder"
export const CANCEL_ORDER_API = "/tmf-api/productOrderingManagement/v5/cancelProductOrder"
export const DEVICE_API_LISTING ="/devices"
export const DEVICE_API = "/device"
export const DEVICE_UPDATE_API = "/document?updateFile=true"
export const PARTNER_LISTING_API = "/tmf-api/party/v4/organization?organizationType=Partner"
export const PARTNER_CREATE_API = "/partner"
export const EDIT_PARTNER_API = "/partner"
export const POLICY_LIST_API = "/commissionPolicy"
export const PARTNER_ALLLISTING_API = "/partner"
export const INVOICE_FOR_CUSTOMER_AND_LISTING_API = "/invoices"
export const INVOICE_STATISTICS_API = "/invoices/statistics"
export const DEVICE_MANAGEMENT_API="/devices?status=Unassigned"
export const CREATE_POLICY_API = "/commissionPolicy"
export const PAYMENT_API="/payment/history"
export const POPULAR_PLAN='/getPopularOffers'
export const STASTICAL_API='/statistics'
export const IMPORT_OFFER_DATA = '/loadProductPricing'
export const TOP_GROUP_METRICS_API = "metrics/top-group?unit=Meter&groupingAttribute=Team"
export const POLICY_LISTING_API='/policies'
export const POLICY_API='/policy'
export const MVNO_DASHBOARD_API="/statistics/customer"
export const USER_MANAGEMENT_API="/users"
export const USER_MANAGEMENT_CREATE_API="/user"
export const OPERATOR_ALLLISTING_API= "/getAllOperators"
export const OPERATOR_CREATE_AND_EDIT_API= "/operator"
export const RESET_PASSWORD_API = "/user/password/reset/url"
export const EXPIRY_CHECK_API = "/password/reset/expiry"
export const RESET_CREATE_PASSWORD_API = "/password/reset"
export const OPERATOR_GET_OPERATOR_API= "/getOperator"
export const SUBSCRIBE_API="/subscribe"
export const CUSTOMER_CHAT_AI= "/chat"
export const CHANGE_PLAN = "/changePlan"
export const GET_ALL_TASKS = "/tasks"
export const GET_TASK_BY_ID = "/task"
export const STATISTICS_OF_TASKS = "/statistics/task"
export const VIEW_API="/view"
export const GET_SERVICE = "/getService"
export const DEACTIVATE = "/deactivate"
export const SET_CREATE_PASSWORD_API = "/password/set"
export const EMAIL_VERIFICATION = "/user/email"
