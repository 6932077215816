import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPermissions } from "../permissionsSlice";

const Navdata = () => {
  const history = useNavigate();
  const [isDashboard, setIsDashboard] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isStation, setIsStation] = useState(false);
  const [isReservation, setIsResevation] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isPricing, setIsPricing] = useState(false);
  const [isDevice, setIsDevice] = useState(false);
  const [isCustomerDashboard, setIsCustomerDashboard] = useState(false)
  const [isOperator, setIsOperator] = useState(false)
  const [isSetting, setIsSetting] = useState(false)
  const [isProfile,setIsProfile] = useState(false)

  const [isMvnoDashboard, setIsMvnoDashboard] = useState(false)
  const [isUser, setIsUser] = useState(false);
  const [isCustomerInvoice, setIsCustomerInvoice] = useState(false);
  const [isCustomerOrder, setIsCustomerOrder] = useState(false);
  const [isCustomerPayment, setIsCustomerPayment] = useState(false);
  const [isCustomerProfile, setIsCustomerProfile] = useState(false);
  const dispatch = useDispatch();
  const [iscurrentState, setIscurrentState] = useState("");
  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);
  const permissions = useSelector((state) => state.permissions.permissions);
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  const roleName = localStorage.getItem('roleName')
  const customerPermission = permissions.find(
    (resource) => resource.resourceName === "customer"
  );
  const operatorPermission = permissions.find(
    (resource) => resource.resourceName === "operator"
  );
  const stationPermission = permissions.find(
    (resource) => resource.resourceName === "station"
  );
  const offerPermission = permissions.find(
    (resource) => resource.resourceName === "offer"
  );
  const uomPermission = permissions.find(
    (resource) => resource.resourceName === "uom"
  );
  const attributePermission = permissions.find(
    (resource) => resource.resourceName === "uom"
  );
  const resourcePermission = permissions.find(
    (resource) => resource.resourceName === "resource"
  );
  const servicePermission = permissions.find(
    (resource) => resource.resourceName === "service"
  );
  const reservationPermission = permissions.find(
    (resource) => resource.resourceName === "reservation"
  );
  const dashboardPermission = permissions.find(
    (resource) => resource.resourceName === "dashboard"
  );
  const devicePermission = permissions.find(
    (resource) => resource.resourceName === "device"
  );
  const PartnerPermission = permissions.find(
    (resource) => resource.resourceName === "partner"
  );
  const commissionPolicyPermission = permissions.find(
    (resource) => resource.resourceName === "commissionPolicy"
  );
  const invoicePermission = permissions.find(
    (resource) => resource.resourceName === "invoice"
  );
  const orderPermission = permissions.find(
    (resource) => resource.resourceName === "order"
  );
  const paymentPermission = permissions.find(
    (resource) => resource.resourceName === 'payment'
  )
  const policyPermission = permissions.find(
    (resource) => resource.resourceName === 'policy'
  )
  const taskPermission = permissions.find(
    (resource) => resource.resourceName === 'task'
  )
  const userPermission = permissions.find(
    (resource) => resource.resourceName === 'userManagement'
  )
  const profilePermission = permissions.find(
    (resource) => resource.resourceName === 'profileManagement'
  )
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Customer") {
      setIsCustomer(false);
    }
    if (iscurrentState !== "Station") {
      setIsStation(false);
    }
    if (iscurrentState !== "Reservation") {
      setIsResevation(false);
    }
    if (iscurrentState !== "Pricing") {
      setIsPricing(false);
    }
    if (iscurrentState !== "Partner") {
      setIsPartner(false);
    }
    if (iscurrentState !== "Device") {
      setIsDevice(false);
    }
    if (iscurrentState !== "CustomerDashboard") {
      setIsCustomerDashboard(false)
    }
    if (iscurrentState !== "MvnoDashboard") {
      setIsMvnoDashboard(false)
    }
    if (iscurrentState !== "CustomerInvoice") {
      setIsCustomerInvoice(false)
    }
    if (iscurrentState !== "CustomerOrder") {
      setIsCustomerOrder(false)
    }
    if (iscurrentState !== "User") {
      setIsUser(false);
    }
    if(iscurrentState !== "Profile"){
      setIsProfile(false)
    }
  }, [
    history,
    iscurrentState,
    isCustomerDashboard,
    isDashboard,
    isCustomer,
    isStation,
    isReservation,
    isPricing,
    isPartner,
    isDevice,
    isUser,
    isProfile
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    dashboardPermission &&  roleName !== "Customer"
      ? {
        id: "dashboard",
        label: "Dashboard",
        icon: "mdi mdi-speedometer",
        link: "/dashboard",
        stateVariables: isDashboard,
        click: function (e) {
          e.preventDefault();
          setIsDashboard(!isDashboard);
          setIscurrentState("Dashboard");
          updateIconSidebar(e);
        },
      }
      : null,
    roleName === "Customer" ? {
      id: "home",
      label: "Home",
      icon: "ri ri-home-2-line",
      link: "/dashboard",
      stateVariables: isHome,
      click: function (e) {
        e.preventDefault();
        setIsHome(!isHome);
        setIscurrentState("Home");
        updateIconSidebar(e);
      },
    }
    : null,
    roleName === "Customer" && orderPermission ? {
      id: "customerOrder",
      label: "Orders",
      icon: "ri ri-shopping-cart-line",
      link: "/customer/order",
      stateVariables: isCustomerOrder,
      click: function (e) {
        e.preventDefault();
        setIsCustomerDashboard(!isCustomerOrder)
        setIscurrentState("CustomerOrder")
        updateIconSidebar(e)
      }
    } : null,
    roleName === "Customer" && invoicePermission ? {
      id: "customerInvoice",
      label: "Invoice",
      icon: "ri ri-bill-line",
      link: "/customer/invoice",
      stateVariables: isCustomerInvoice,
      click: function (e) {
        e.preventDefault();
        setIsCustomerDashboard(!isCustomerInvoice)
        setIscurrentState("CustomerInvoice")
        updateIconSidebar(e)
      }
    } : null,
    roleName === "Customer" && paymentPermission ? {
      id: "customerPayment",
      label: "Payments",
      icon: "ri ri-bank-card-line",
      link: "/customer/payment",
      stateVariables: isCustomerPayment,
      click: function (e) {
        e.preventDefault();
        setIsCustomerDashboard(!isCustomerPayment)
        setIscurrentState("customerPayment")
        updateIconSidebar(e)
      }
    } : null,
    // roleName === "Customer" && customerPermission ? {
    //   id: "customerProfile",
    //   label: "Profile",
    //   icon: "ri ri-user-3-line",
    //   link: "/customer/profile",
    //   stateVariables: isCustomerProfile,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsCustomerDashboard(!isCustomerProfile)
    //     setIscurrentState("customerPayment")
    //     updateIconSidebar(e)
    //   }
    // } : null,
    PartnerPermission && roleName !== 'Customer'
      ? {
        id: "partner",
        label: "Partners",
        icon: " ri-group-line",
        link: "/#",
        stateVariables: isPartner,
        click: function (e) {
          e.preventDefault();
          setIsPartner(!isPartner);
          setIscurrentState("Partner");
          updateIconSidebar(e);
        },
        subItems: [
          PartnerPermission &&
            PartnerPermission.permissionList.includes("list")
            ? {
              id: "partner",
              label: "Partners",
              link: "/partner-listing",
              parentId: "partner",
            }
            : null,
          commissionPolicyPermission &&
            commissionPolicyPermission.permissionList.includes("list")
            ? {
              id: "settlement-policy",
              label: "Settlement Policies",
              link: "/policy",
              parentId: "partner",
            }
            : null,
          PartnerPermission &&
            PartnerPermission.permissionList.includes("create")
            ? {
              id: "create-partner",
              label: "Add Partner",
              link: "/create-partner",
              parentId: "partner",
            }
            : null,
        ].filter(Boolean),
      }
      : null,
    customerPermission && roleName !== 'Customer'
      ? {
        id: "customer",
        label: "Customer",
        icon: "ri ri-user-3-line",
        link: "/#",
        stateVariables: isCustomer,
        click: function (e) {
          e.preventDefault();
          setIsCustomer(!isCustomer);
          setIscurrentState("Customer");
          updateIconSidebar(e);
        },
        subItems: [
          customerPermission &&
            customerPermission.permissionList.includes("list")
            ? {
              id: "customer",
              label: roleName === "telcoAdmin" ? "Customer Search" : "Customers",
              link: "/customer",
              parentId: "customer",
            }
            : null,
          customerPermission &&
            customerPermission.permissionList.includes("create")
            ? {
              id: "create-customer",
              label: "Add Customer",
              link: "/create-customer",
              parentId: "customer",
            }
            : null,
          orderPermission &&
            orderPermission.permissionList.includes("list")
            ? {
              id: "orders",
              label: "Orders",
              link: "/orders",
              parentId: "customer",
            }
            : null,
          invoicePermission &&
            invoicePermission.permissionList.includes("list")
            ? {
              id: "invoices",
              label: "Invoices",
              link: "/invoices",
              parentId: "customer",
            }
            : null,
            taskPermission &&
            taskPermission.permissionList.includes("list")
            ? {
              id: "tasks",
              label: "Tasks",
              link: "/tasks",
              parentId: "customer",
            }
            : null,
        ].filter(Boolean),
      }
      : null,
      (roleName === "systemUser" || roleName === "providerOperationUser") && operatorPermission ? {
        id: "operator",
        label: "Operators",
        icon: " ri-group-line",
        link: "/operator-listing",
        stateVariables: isOperator,
        click: function (e) {
          e.preventDefault();
          setIsOperator(!isOperator)
          setIscurrentState("operator")
          updateIconSidebar(e)
        }
      } : null,
      (roleName === "systemUser" || roleName === "providerOperationUser") && operatorPermission ? {
        id: "settings",
        label: "Settings",
        icon: "ri-settings-3-line",
        link: "/operator-listing",
        stateVariables: isSetting,
        click: function (e) {
          e.preventDefault();
          setIsSetting(!isSetting)
          setIscurrentState("settings")
          updateIconSidebar(e)
        }
      } : null,
    devicePermission && roleName !== 'Customer'
      ? {
        id: "Device",
        label: "Device",
        icon: "ri-router-line",
        link: "/#",
        stateVariables: isDevice,
        click: function (e) {
          e.preventDefault();
          setIsDevice(!isDevice);
          setIscurrentState("Device");
          updateIconSidebar(e);
        },
        subItems: [
          devicePermission &&
            devicePermission.permissionList.includes("list")
            ? {
              id: "customer",
              label: "Devices",
              link: "/devices",
              parentId: "customer",
            }
            : null,
          devicePermission &&
            devicePermission.permissionList.includes("create")
            ? {
              id: "customer",
              label: "Add Device",
              link: "/create-device",
              parentId: "customer",
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    stationPermission && roleName !== 'Customer'
      ? {
        id: "station",
        label: "Station",
        icon: "bx bxs-ev-station",
        link: "/#",
        stateVariables: isStation,
        click: function (e) {
          e.preventDefault();
          setIsStation(!isStation);
          setIscurrentState("Station");
          updateIconSidebar(e);
        },
        subItems: [
          stationPermission &&
            stationPermission.permissionList.includes("list")
            ? {
              id: "station",
              label: "Stations",
              link: "/station",
              parentId: "station",
            }
            : null,
        ].filter(Boolean),
      }
      : null,
    reservationPermission && roleName !== 'Customer'
      ? {
        id: "reservation",
        label: "Reservation",
        icon: "ri ri-reserved-line",
        link: "/#",
        stateVariables: isReservation,
        click: function (e) {
          e.preventDefault();
          setIsResevation(!isReservation);
          setIscurrentState("Reservation");
          updateIconSidebar(e);
        },
        subItems: [
          reservationPermission &&
            reservationPermission.permissionList.includes("list")
            ? {
              id: "reservation",
              label: "Reservations",
              link: "/reservation",
              parentId: "reservation",
            }
            : null,
          reservationPermission &&
            reservationPermission.permissionList.includes("create")
            ? {
              id: "create reservation",
              label: "Add Reservation",
              link: "/create-reservation",
              parentId: "createreservation",
            }
            : null,
        ].filter(Boolean),
      }
      : null,
    (offerPermission || uomPermission || resourcePermission || servicePermission || attributePermission) && roleName !== 'Customer'
      ? {
        id: "pricing",
        label: "Pricing",
        icon: "las la-money-bill",
        link: "/#",
        stateVariables: isPricing,
        click: function (e) {
          e.preventDefault();
          setIsPricing(!isPricing);
          setIscurrentState("Pricing");
          updateIconSidebar(e);
        },
        subItems: [
          uomPermission && uomPermission.permissionList.includes("create")
            ? {
              id: "uom",
              label: "UOM",
              link: "/uom",
              parentId: "pricing",
            }
            : null,
          attributePermission && attributePermission.permissionList.includes("create")
            ? {
              id: "attribute",
              label: "Attributes",
              link: "/attribute",
              parentId: "pricing"
            } : null,
          resourcePermission &&
            resourcePermission.permissionList.includes("list")
            ? {
              id: "resources",
              label: "Resources",
              link: "/resource",
              parentId: "pricing",
            }
            : null,
          servicePermission
            // && servicePermission.permissionList.includes("create")
            ? {
              id: "services",
              label: "Services",
              link: "/service",
              parentId: "pricing",
            }
            : null,
          offerPermission && offerPermission.permissionList
            ? {
              id: "offer",
              label: "Offers",
              link: "/offer",
              parentId: "pricing",
            }
            : null,
          policyPermission && policyPermission.permissionList.includes("create") && offerPermission && offerPermission.permissionList.includes("create")
            ? {
              id: "Policy",
              label: "Policy Specs",
              link: "/policies",
              parentId: "pricing",
            }
            : null,
        ].filter(Boolean),
      }
      : null,
    roleName !== 'Customer' && ((userPermission && userPermission.permissionList.includes("list")) || (profilePermission && 
    profilePermission.permissionList.includes("view") && (roleName === "operatorAdmin" || roleName === "telcoAdmin" || roleName === "ISPOperatorAdmin" || roleName === "genericAdmin")))
      ? {
        id: "user",
        label: "Administration",
        icon: "ri ri-admin-line",
        link: "/#",
        stateVariables: isUser,
        click: function (e) {
          e.preventDefault();
          setIsUser(!isUser);
          setIscurrentState("User");
          updateIconSidebar(e);
        },
        subItems: [
          userPermission &&
          userPermission.permissionList.includes("list")
            ? {
              id: "user",
              label: "Users ",
              link: "/user",
              parentId: "user",
            }
            : null,
            profilePermission && 
            profilePermission.permissionList.includes("view") && (roleName === "operatorAdmin" || roleName === "telcoAdmin" || roleName === "ISPOperatorAdmin" || roleName === "genericAdmin")
              ? {
                id: "operator",
                label: "Business Profile",
                link: "/business-profile",
                parentId: "operator",
              }
              : null,
        ].filter(Boolean),
      }
      : null,
  ].filter(Boolean);
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
