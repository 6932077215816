import React from 'react';
import ApexCharts from 'react-apexcharts';

const LineChart = () => {
  const options = {
    chart: {
      id: 'line-chart',
    },
    xaxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      title: {
        text: '',
      },
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      size: 5,
    },
    tooltip: {
      enabled: true,
    },
  };

  const series = [
    {
      name: 'Data Consumed',
      data: [10, 5, 18, 10, 8, 30, 25], 
    },
  ];

  return (
    <div className="line-chart">
      <ApexCharts
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
