import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { MaterialReactTable } from 'material-react-table';
import { handleExportData } from '../../Utils/ExportToCsv';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUom } from '../../../slices/uom/thunk';
import { uomColoumns } from './constants/coloumn';
import { Loader } from '../../Utils/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { setEditSuccessMessage, setSuccessMessage } from '../../../slices/toastMessage/action';
import { PAGE_TITLES } from '../../Common/constants';
import { MenuItem } from "@mui/material";
import CommonModal from "../../Utils/CommonModal";
import { deleteUom } from '../../../slices/uom/deleteUom/thunk';
import { useColumnSettings } from '../../Utils/useColumnSettings';
import { getView } from '../../../slices/View/thunk';

function UomListings() {
    const [loading, setLoading] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [uomName, setUomName] = useState("");
    const [modal, setModal] = useState(false);
    
    const columns = uomColoumns();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const uom = useSelector((state) => state.Uom.uom);
    const permissions = useSelector((state) => state.permissions.permissions);
    const uomPermission = permissions.find((resource) => resource.resourceName === 'uom');

    const {
        settingsModal,
        visibleColumns,
        selectedView,
        viewColumns,
        modalPosition,
        settingsIconRef,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsModal
    } = useColumnSettings('uom', columns);

    useEffect(() => {
        dispatch(fetchAllUom(setLoading));
        dispatch(getView(setLoading));
    }, [dispatch]);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`UOM Created Successfully`);
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success(`UOM Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch]);

    const navigateToAddUnit = () => {
        let path = "/create-unit";
        navigate(path);
    };

    const toggleModal = (id) => {
        setUomName(id);
        setModal(!modal);
        document.body.style.overflow = 'auto';
    };

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.UOM_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {uom ?
                                                <>
                                                    <MaterialReactTable
                                                        icons={{
                                                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                        }}
                                                        muiTableHeadCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }}
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }}
                                                        renderTopToolbarCustomActions={({ table }) => (
                                                            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                                <div className="d-flex flex-column flex-md-row">
                                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(uom) }}>
                                                                        <i className="ri-upload-line label-icon align-middle fs-16 me-2"></i> Export Data
                                                                    </Button>
                                                                    {uomPermission && uomPermission.permissionList.includes('create') &&
                                                                        <Button color="secondary" className="btn-label me-2" onClick={navigateToAddUnit}>
                                                                            <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Unit
                                                                        </Button>}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div color="secondary" className="btn-icon me-2" ref={settingsIconRef} onClick={toggleSettingsModal}>
                                                                        <i className="ri-settings-2-line fs-4"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        columns={columns.filter((column) => viewColumns.includes(column.accessorKey))}
                                                        data={uom}
                                                        enableColumnOrdering
                                                        enableColumnDragging
                                                        enableRowActions={true}
                                                        displayColumnDefOptions={{
                                                            'mrt-row-actions': {
                                                                header: '',
                                                                enableHiding: true
                                                            }
                                                        }}
                                                        renderRowActionMenuItems={({ closeMenu, row }) => [
                                                            <MenuItem key={`${row.index}.2`} onClick={() => { navigate(`/edit-uom/${row.original.name}`) }}><i className="bx bxs-edit me-2" /> Edit</MenuItem>,
                                                            <MenuItem className='row-options'
                                                                key="delete"
                                                                onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this UOM "${row.original.name}"?`)
                                                                    toggleModal(row);
                                                                    closeMenu();
                                                                }}
                                                            >
                                                                <i className="ri ri-delete-bin-5-line me-2" /> Delete
                                                            </MenuItem>,
                                                        ]}
                                                        enableDensityToggle={false}
                                                    />
                                                </>
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {SettingsModal()}
                <CommonModal messageForPopUp={messageForPopUp} toggle={toggleModal} open={modal} buttonText={"Yes, Delete It"} modalAction={() => {
                    dispatch(deleteUom(uomName.original.name, setLoading, navigate));
                    toggleModal();
                }} />
            </div>
        </React.Fragment>
    );
}

export default UomListings;
