import moment from "moment"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

export const DetailedTaskView = ({ taskDetails }) => {
    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">View Task</h4>
            </CardHeader>
            <CardBody>
                <Row className="gy-4 mb-4">
                    <Col>
                        <div className=" fw-semibold mb-2">Task Type</div>
                        <div>
                            {taskDetails.taskType}
                        </div>
                    </Col>
                    <Col>
                        <div className={"fw-semibold mb-2"}>Provisioning Status</div>
                        <div> 
                            <span className={`${taskDetails?.provisioningStatus === "FAILED" ? "task-fail-color" : taskDetails?.provisioningStatus === "SUCCESS" ? "task-success-color" :taskDetails?.provisioningStatus === "INPROGRESS" ? "task-inprogress-color" : ""}`}>
                                {taskDetails?.provisioningStatus}
                            </span>
                        </div>
                    </Col>

                    <Col>
                        <div className=" fw-semibold mb-2">Request Time</div>
                        <div>
                            {moment(taskDetails?.taskTime).format("DD MMM YYYY, HH:MM:SS")}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className=" fw-semibold mb-2">Request Sent</div>
                    <div>
                        <pre className="text-warning">
                            {JSON.stringify(taskDetails?.requestSent, null, 2)}
                        </pre>
                    </div>
                    <div className=" fw-semibold mb-2">Response Received</div>
                    <pre className="text-warning">
                        {JSON.stringify(taskDetails?.responseReceived, null, 2)}
                    </pre>
                </Row>
            </CardBody>
        </Card>
    )
}