import { fetchDeleteServiceFailure, fetchDeleteServiceSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { ADD_SERVICE_API } from "../../constants/constants";
import { getAllService } from "../thunk";

export const deleteService = (id, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_SERVICE_API}/${id.original.serviceCode}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(fetchDeleteServiceSuccess(response))
            setLoading(false)
            toast.success(`Service Deleted Successfully`)
            dispatch(getAllService(setLoading));
        }
        if (response.error) {
            dispatch(fetchDeleteServiceFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}