import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

export default function CustomerSubscriptionCard({ fetchCustomerSummaryData }) {
    const addOnResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn);
    const nonAddOnResources = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn);
    const addOnCount = addOnResources?.length || 0;
    const nonAddOnCount = nonAddOnResources?.length || 0;
    const totalSubscriptions = addOnCount + nonAddOnCount;

    return (
        <Card className="shadow-sm flex-fill">
            <CardBody className="d-flex justify-content-center align-items-stretch">
                <div className="row w-100">
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center border-end">
                        <h2 className="mb-3 mb-md-4">{totalSubscriptions}</h2>
                        <CardText className="text-center title-disable-color-edit-offer">
                            {totalSubscriptions <= 1 ? "Active Subscription" : "Active Subscriptions"}
                        </CardText>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center text-center">
                        <h2 className="mb-3 mb-md-4">{nonAddOnCount}</h2>
                        <CardText className="mb-3 mb-md-4 title-disable-color-edit-offer">Base Offer</CardText>
                        <h2 className="mb-3 mb-md-4">{addOnCount}</h2>
                        <CardText className="title-disable-color-edit-offer">Add On Offer</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}