import { USER_MANAGEMENT_API, USER_MANAGEMENT_CREATE_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setEditSuccessMessage, setEmailUpdateSuccessMessage } from "../../toastMessage/action"
import { toast } from "react-toastify"
import { fetchUpdateUserFailure, fetchUpdateUserSuccess } from "./reducer"
import { documentUpdateJson, uploadDocApiCall } from "../../../Components/UserManagement/Constant/JsonConstant"
import { documentUpdateFile } from "../../customer/documentupdatefile/thunk"

export const updateUser = (setLoading, formattedValues, navigate, userId, isProfile, profile, update, setUpdate, fileSelected, logoFile, editData, setFileSelected) => (dispatch) => {
    const roleName = localStorage.getItem("roleName")
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}/${userId}`, 'PATCH', formattedValues).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdateUserSuccess(response))
            if (logoFile) {
                if (profile && update) {
                    setUpdate(false)
                }
                if (fileSelected && logoFile !== null) {
                    if (profile && update) {
                        const jsonObj = documentUpdateJson(logoFile, userId, editData)
                        let partnerOrCustomer = "User";
                        dispatch(documentUpdateFile(setLoading, jsonObj, userId, editData?.userDetails?.userName, partnerOrCustomer, setUpdate, setFileSelected, navigate));
                    }
                    else {
                        uploadDocApiCall(logoFile, userId, editData, setLoading, setFileSelected, dispatch, navigate)
                    }
                }
                else {
                    if (isProfile && roleName === 'telcoEditor') {
                        navigate("/dashboard")
                    }
                    else if (isProfile && roleName === 'systemUser') {
                        navigate("/operator-listing")
                    }
                    else {
                        
                        if (response.message) {
                            dispatch(setEmailUpdateSuccessMessage(true))
                        }
                        else {
                            dispatch(setEditSuccessMessage(true))
                        }
                        navigate("/user")
                    }
                }
            }
            else {
                if (isProfile && (roleName === 'telcoEditor' || roleName === 'operatorAdmin')) {
                    if (response.message) {
                        dispatch(setEmailUpdateSuccessMessage(true))
                    }
                    else {
                        dispatch(setEditSuccessMessage(true))
                    }
                    navigate("/dashboard")
                }
                else if (isProfile && roleName === 'systemUser') {
                    if (response.message) {
                        dispatch(setEmailUpdateSuccessMessage(true))
                    } else {
                        toast.success("Profile Updated Successfully")
                    }
                    navigate("/operator-listing")
                }
                else {
                    if (response.message) {
                        dispatch(setEmailUpdateSuccessMessage(true))
                        navigate("/user")
                    }
                    else {
                        dispatch(setEditSuccessMessage(true))
                        navigate("/user")
                    }
                   
                }
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateUserFailure(response.error.message))
        }
    }).catch((error) => {
        toast.error(
            `${error.message}`
        )
    })
}